<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <RetailerMenu></RetailerMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-2">
        <h2>{{userStoreDetail.Retailer.companyName}}</h2>

        <v-row class="mt-5">
          <DistributorProducts></DistributorProducts>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RetailerMenu from "../others/RetailerMenu.vue";
import DistributorProducts from "../products/DistributorProducts.vue";

export default {
  name: "RetailerDashboard",

  components: {
    RetailerMenu,
    DistributorProducts,
  },

  data: () => ({
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Account",
        disabled: true,
        href: "/",
      },
    ],
    userStoreDetail:{
      Retailer:
      {
        companyName:""
      }
    }
  }),
  created() {
    this.fetchStoreDetail();
  },
  methods:{
    fetchStoreDetail() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem('userId'))
        .then((response) => {
          console.log(response);
          this.userStoreDetail = response.data.data;
        });
    }
  }
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.iconPadding {
  padding: 50px !important;
}

.iconStyle {
  color: yellowgreen;
  font-size: 45px;
}
</style>
